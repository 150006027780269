@import "../fonts/suisse/stylesheet.css";

@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-Regular.ttf");
  src: url("../fonts/GraphikLCG-Regular.ttf?#iefix") format("embedded-truetype"),
    url("../fonts/GraphikLCG-Regular.woff2") format("woff2"),
    url("../fonts/GraphikLCG-Regular.woff") format("woff"),
    url("../fonts/GraphikLCG-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik";
  src: url("../fonts/Graphik-Regular-Web.eot");
  src: url("../fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Regular-Web.woff") format("woff"),
    url("../fonts/Graphik-Regular-Web.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Graphik";
  src: url("../fonts/Graphik-Medium-Web.eot");
  src: url("../fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Medium-Web.woff") format("woff"),
    url("../fonts/Graphik-Medium-Web.ttf") format("truetype");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Graphik";
  src: url("../fonts/Graphik-Bold-Web.eot");
  src: url("../fonts/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Bold-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Bold-Web.woff") format("woff"),
    url("../fonts/Graphik-Bold-Web.ttf") format("truetype");
}
