@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Light.woff2') format('woff2'),
        url('SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Black.woff2') format('woff2'),
        url('SuisseIntl-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl Book';
    src: url('SuisseIntl-BookItalic.woff2') format('woff2'),
        url('SuisseIntl-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-LightItalic.woff2') format('woff2'),
        url('SuisseIntl-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-BlackItalic.woff2') format('woff2'),
        url('SuisseIntl-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Bold.woff2') format('woff2'),
        url('SuisseIntl-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-BoldItalic.woff2') format('woff2'),
        url('SuisseIntl-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl Book';
    src: url('SuisseIntl-Book.woff2') format('woff2'),
        url('SuisseIntl-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-ThinItalic.woff2') format('woff2'),
        url('SuisseIntl-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Thin.woff2') format('woff2'),
        url('SuisseIntl-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-SemiBold.woff2') format('woff2'),
        url('SuisseIntl-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Medium.woff2') format('woff2'),
        url('SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-MediumItalic.woff2') format('woff2'),
        url('SuisseIntl-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
        url('SuisseIntl-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-UltraLightItalic.woff2') format('woff2'),
        url('SuisseIntl-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Italic.woff2') format('woff2'),
        url('SuisseIntl-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-Regular.woff2') format('woff2'),
        url('SuisseIntl-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('SuisseIntl-UltraLight.woff2') format('woff2'),
        url('SuisseIntl-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

